
    .tab-title{
        color: #112467;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
    }
    .tab-title-bold{
        font-weight: 700;
        font-size: 14px;
        color: #112467;
    }
    .tab-title-border{
        position: relative;
        display: inline-block;
    }

    .tab-title-border::before{
        content: '';
        position: absolute;
        background-color:#112467;
        width: 100%;
        height: 4px;
        border-radius: 6px;
        bottom: -10px;
        left: 0;
        right: 0;
    }
