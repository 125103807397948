.transferkits-modal {
    border-radius: 12px;
    padding: 38px 30px 60px 30px;
    position: relative;
}

.transferkits-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.transferkits-btn {
    position: absolute;
    bottom: -10%;
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: center;
}

.transferkits-table-modal {
    /* box-shadow: 0px 20px 27px 0px #0000000D;
    backdrop-filter: blur(13.5914px);
    -webkit-backdrop-filter: blur(13.5914px);
    background: #ffffffbf; */
    background-color: white;
    border-radius: 12px;
    padding: 20px 20px 40px 20px;
    position: relative;
}

.transferkits-table-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 1px;
    text-align: left;
}