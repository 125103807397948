#createRetailer-checking {
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 700;
  /* line-height: 17px; */
  text-align: right;
  color: rgba(121, 40, 202, 1);
}

.createRetailer-bouncing-loader>span {
  width: 10px;
  height: 10px;
  /* margin: 3px 6px; */
  border-radius: 70%;
  /* background-color: #a3a1a1; */
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
  }
}

.createRetailer-bouncing-loader>span:nth-child(2) {
  animation-delay: 0.2s;
}

.createRetailer-bouncing-loader>span:nth-child(3) {
  animation-delay: 0.4s;
}

.createRetailer-bouncing-loader>span:nth-child(4) {
  animation-delay: 0.6s;
}

.createRetailer-bouncing-loader>span:nth-child(5) {
  animation-delay: 0.8s;
}