.usercard-container {
    border-radius: 8px;
    padding: 20px 20px 0 20px;
    background-color: white;
    /* width: 300px; */
}

.usercard-logo-container {
    display: flex;
    align-items: center;
}

.usercard-logo-container div {
    padding: 5px;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background: linear-gradient(180deg, #189DFF 0%, #1850FF 100%);
    display: flex;
    justify-content: center;
    align-items: center;

}

.usercard-user-id {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #80808F;
    margin: 5px 0;
}

.usercard-user-balance {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #80808F;
}

.usercard-user-balancekit {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #80808F;
}

.usercard-user-type {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #112467;
}

.usercard-change-password {
    border-top: 1px solid #E5EAEE;
    border-bottom: 1px solid #E5EAEE;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.usercard-change-password>span {
    display: flex;
    align-items: center;
}

.usercard-change-password:hover {
    text-decoration: underline;
}

.usercard-logout {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.usercard-logout:hover {
    text-decoration: underline;
}

.usercard-logout>span {
    display: flex;
    align-items: center;
}

.usercard-logout span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #112467;
}

.usercard-change-password span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #112467;
}

.get-otp-btn-changepassword {
    position: absolute;
    left: 0;
    top: 92%;
}

.passwordfields div>input{
    padding-right: 3rem;
}