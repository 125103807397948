.modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    color: #212121;
}

.modal-line {
    border-bottom: 4px solid #112467;
    width: 35%;
    margin: 0 auto;
    border-radius: 1px;
}

.modal-input {
    border: none;
    border-bottom: 1px solid #112467;
    width: 50%;
    text-align: center;
}

.modal-input:focus {
    border: none;
    background: transparent;
    outline: none;
    border-bottom: 1px solid #112467;
}

.modal-buttons {
    display: flex;
    position: absolute;
    margin: 0 auto;
    left: 0%;
    top: 88%;
    width: 100%;
    padding: 0 60px;
}

.modal-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #112467;
}

.modal-eye-password {
    position: absolute;
    cursor: pointer;
    margin: -1px -2rem;
}