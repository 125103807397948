/*
.rdt_Table {
    width: 100%;
}

.rdt_TableHeadRow {
    border-bottom: 0 !important;
}

.rdt_TableHead {
    width: auto;
    white-space: nowrap;
    border-right: 0 !important;
}

.rdt_TableCol {
    width: 100% !important;
    padding: 0 !important;
    padding: 0 10px !important;
    border-right: 1px solid #d2d2d6;
    min-width: 150px !important;
}

.rdt_TableCol_Sortable>div {
    color: #112467;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    white-space: nowrap !important;
    overflow: visible !important;
}

.rdt_TableCol_Sortable>span {
    padding: 0 5px !important;
}

.rdt_TableCol:last-child {
    border-right: 0 !important;
}

.rdt_TableRow {
    border: 0 !important;
}

.rdt_TableCell {
    width: auto;
    padding: 10px !important;
    margin-right: 20px;
    align-items: flex-start !important;
    min-height: 52px !important;
    margin: 0 !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    user-select: none;
    vertical-align: top;
}

.rdt_TableCell>div {
    color: #112467;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    white-space: normal !important;
}

.rdt_Table {
    border-collapse: separate;
    border-spacing: 0;
}

.rdt_TableHead,
.rdt_TableCell {
    border-bottom: 1px solid rgba(210, 210, 214, 1);
    border-right: 1px solid rgba(210, 210, 214, 1);
}

.rdt_TableCell:last-child {
    border-right: none;
}

.rdt_TableBody :last-child .rdt_TableCell {
    border-bottom: none;
}

.rdt_TableHead:last-child {
    border-right: none;
}

.rdt_TableCell:last-child {
    border-right: none;
}

.table-container>div {
    border: 1px solid #d2d2d6;
    border-radius: 10px;
}

.table-container>div:last-child {
    border: 0 !important;
}


.rdt_Pagination{
    color: #112467 !important;
    border-top: none !important;
}
*/

.table-container>div::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    scroll-behavior: smooth;
}

.table-container>div::-webkit-scrollbar-track {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.table-container>div::-webkit-scrollbar-thumb {
    border-radius: 12px !important;
    background-color: #112467 !important;
}

/* .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.Mui-focused {
    border: none !important;
    ;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 20px;
}

.css-wjh20t-MuiPagination-ul>li {
    user-select: none;
} */

.rdt_TableCell div {
    white-space: normal !important;
}