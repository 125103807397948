#payment-modal {
    border-radius: 12px;
    padding: 0 30px;
}

#payment-modal-title {
    padding-top: 38px;
    font-weight: 700;
    font-size: 14px;
    color: #112467;
}