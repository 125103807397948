@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Libre Franklin', sans-serif;
}

body {
    background-color: #F3F6F9;
    user-select: none;
}

.errorText {
    font-size: 12px !important;
    color: red !important;
}

.modal-transparency {
    /* opacity: .75; */
    /* box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(13.5914px);
    -webkit-backdrop-filter: blur(13.5914px); */
    box-shadow: 0px 20px 27px 0px rgb(0 0 0 / 7%);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1.5914px);
    -webkit-backdrop-filter: blur(1.5914px);
}

.copyright-text {
    font-family: Libre Franklin;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
}