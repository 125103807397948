.transactions-report-select {
    padding-left: 30px;
    height: 48px;
    border-radius: 12px;
    width: 100%;
    /* margin-right: 30px; */
    font-family: Libre Franklin;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #8392AB;

}

#transaction-searchtitle {
    font-family: Libre Franklin;
    min-width: 70px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000000;
}

#transactionmodal-container {
    gap: 2%;
    padding: 38px 30px 60px 30px;
    border-radius: 12px;
}

#transaction-report-btn{
    position: absolute;
    bottom: -16%;
    width: 100%;
    left: 0%;
}

#transaction-report-showmore-btn{
    padding: 40px 0;
}