.custom-select {
    /* background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 100%;
    height: 48px; */
    /* background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%); */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 100%;
    padding-left: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: center;
    height: 40px;
}

.custom-icon-select-container{
    /* flex justify-between gap-10  */
    display: flex;
    justify-content: space-between;
    gap: 10%;
}

.custom-icon-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: center;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
}

.iconwith > div {
  padding-left: 45px;
}