main {
    width: 100%;
    min-height: 100vh;
}

.logo {
    font-size: 30px;
    display: block;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebaropen-openicon {
    display: flex;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 30px;
    padding: 0 15px;
}

.sidebaropen-container {
    height: calc(100% - 96px);
    /* height: 100%; */
    overflow-y: scroll;
}

.link {
    display: flex;
    margin: 20px 15px;
    gap: 15px;
    transition: all 0.5s;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    align-items: center;
    color: #67748E;
}

.link:hover {
    background: #FFFFFF;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #112467;
    transition: all 0.5s;
}

#sidebaropen-navlink-container .active {
    background: #FFFFFF;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.02em;
    align-items: center;
    color: #112467;
}

#sidebaropen-navlink-container .active .icon svg {
    fill: white;
    background: #112467;
    border-radius: 10px;
}

.icon {
    background: #FFFFFF;
    fill: #112467;
    border-radius: 10px;
    font-size: 14px;
}

.link_text {
    font-size: 14px;
    display: flex;
    padding-left: 20px;
}

a {
    height: 48px;
}

.sidebaropen-container::-webkit-scrollbar {
    width: 5px;
}

.sidebaropen-container::-webkit-scrollbar-track {
    margin: 26px 0;
}