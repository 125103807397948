.header {
    /* background-image: url("../../../assets/images/banner.jpg"), linear-gradient(135deg, #FF0080 0%, #7928CA 100%); */
    mix-blend-mode: normal;
    border-radius: 12px;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px 25px 0 25px;
}

.header-header {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}

.header-main-title{
    font-size: 18px;
}

.user-container{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    width: 150px;
    height: 100%;
}

.head-titles{
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}