.input-background {
    background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%);
    color: #112467;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375rem;
    letter-spacing: 0.00938rem;
}

.input-background::placeholder {
    color: #8392AB;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375rem;
    letter-spacing: 0.00938rem;
}

.validation-input {
    height: 40px;
    /* background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%); */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 0;
    /* padding-left: 25px; */
}


#validation-span-mobile {
    background: #112467;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}

#validation-input-mobile {
    /* color: #979797; */
    height: 40px;
    width: 100%;
    /* width: calc(100% - 25px); */
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    padding-left: 25px;
    /* background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%); */
}

#validation-span-symbol {
    /* background: white; */
    height: 40px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}

#validation-input-symbol {
    height: 40px;
    width: 100%;
    /* color: #979797; */
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;

}

.validation-container-symbol {
    /* background: linear-gradient(91.91deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.4) 100.82%); */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin: 23px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10%;
}

.message-tag {
    position: absolute;
    right: 0%;
    padding-right: 30px;
    font-size: 12px;
}

input:focus {
    outline: none;
}